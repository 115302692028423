// import jQuery from "jquery";
import '@fancyapps/fancybox/src/css/core.css';
import '@fancyapps/fancybox/src/css/thumbs.css';

import "bootstrap";
import jQuery from "jquery"
window.jQuery = jQuery;
window.$ = jQuery;
import {gsap} from "./gsap";
import {ScrollTrigger} from "./gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
require("@fancyapps/fancybox");
jQuery(document).ready(function ($) {

    $('.bapis__accordition-title--1 button').click(function () {
        if ($(this).closest('.card').hasClass('active')) {
            $(this).closest('.card').removeClass('active');
        } else {
            $('.bapis__accordition .card--1').removeClass('active')
            $(this).closest('.card').addClass('active');
        }
    });
    $('.bapis__accordition-title--2 a').click(function () {
        if ($(this).closest('.card').hasClass('active')) {
            $(this).closest('.card').removeClass('active');
        } else {
            $('.bapis__accordition .card--2').removeClass('active')
            $(this).closest('.card').addClass('active');
        }
    });

    $('.menuIcon').on('click',function () {
        $('.rectangleMenu').toggleClass('open');
        $('body').toggleClass('overflow');
        $(this).toggleClass('open');
        $('.ombra').toggleClass('show');
    })
});

if (document.body.classList.contains('life-ryb-actions')) {
    $('.life-ryb-actions__content--3 li a').click(function (){
        var idx = $(this).parent().index();
        var topS = $('.life-ryb-actions__content--2').eq(idx).offset().top +50;

        $("html, body").stop().animate({scrollTop:topS}, 500, 'swing', function() {

        });
    });

    ScrollTrigger.create({
        trigger: ".life-ryb-actions__content--3",
        pin: true,
        start: "top top",
        endTrigger: ".life-ryb-actions__content--1",
        end: "bottom 50%",
    });

    ScrollTrigger.create({
        trigger: ".life-ryb-actions__content--4",
        pin: true,
        start: "top top",
        endTrigger: ".life-ryb-actions__content--1",
        end: "bottom 50%",
        onUpdate : function () {
            var pa = $('.life-ryb-actions__content--3 li.active span').html();
            $('.life-ryb-actions__content--4').html(pa);
        }
    });

    var i = 0;
    gsap.utils.toArray(".life-ryb-actions__content--2").forEach((section) => {
        ScrollTrigger.create({
            trigger: section,
            start: "top top",
            end: "bottom top",
            toggleClass: 'active',
            onUpdate : function () {
                var idx = $(section).index();
                $('.life-ryb-actions__content--3 li').removeClass('active');
                $('.life-ryb-actions__content--3 li').eq(idx).addClass('active');
            }
        });
    });
}
